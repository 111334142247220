<template>
  <div class="container">
    <h5>Apoio:</h5>
    <carousel :items-to-show="4" v-if="listApoio.length > 0">
      <slide v-for="apoio in listApoio" :key="apoio.id">
        <a
          v-if="apoio.url"
          :href="apoio.url"
          :title="apoio.nome"
          target="_blank"
        >
          <img
            class="d-block w-100"
            :src="urlImg + apoio.id"
            :alt="apoio.nome"
            @click="clickApoio(apoio.id)"
            @mouseleave="clickOver(apoio.id)"
          />
        </a>

        <a v-else :title="apoio.nome" target="_blank">
          <img
            class="d-block w-100"
            :src="urlImg + apoio.id"
            :alt="apoio.nome"
            @click="clickApoio(apoio.id)"
            @mouseleave="clickOver(apoio.id)"
          />
        </a>
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>

<script>
import { URL_BASE } from "../services/Commons";
import ApoioService from "../services/apoio.service";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { event } from "vue-gtag";
export default {
  name: "ApoioComponent",
  data: () => ({
    listApoio: [],
    urlBase: "",
    urlImg: "",
  }),
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  mounted() {
    this.urlBase = URL_BASE;
    this.urlImg = URL_BASE + `apoiodowimagem/`;

    ApoioService.getAll(localStorage.locale).then((result) => {
      if (result.status < 400) {
        this.listApoio = result.data;
      }
    });
  },
  methods: {
    clickApoio(idAnuncio) {
      event(
        "click_anuncio",
        {
        'event_category': "anuncio",
        'event_label': "codigo anunciante: "+idAnuncio,
        'value': idAnuncio
      }
      );
    },
    clickOver(idAnuncio) {
      event(
        "over_anuncio",
        {
        'event_category': "anuncio",
        'event_label': "codigo anunciante: "+idAnuncio,
        'value': idAnuncio
      }
      );
    },
  },
};
</script>
<style>
#hideArrowsExample .carousel__prev--in-active,
#hideArrowsExample .carousel__next--in-active {
  display: none;
}
:root {
  /* Color */
  --vc-clr-primary: #cc723e;
  --vc-clr-secondary: #c48c6c;
  --vc-clr-white: #ffffff;
  /* Icon */
  --vc-icn-width: 1.2em;
  /* Navigation */
  --vc-nav-width: 30px;
  --vc-nav-color: #ffffff;
  --vc-nav-background-color: var(--vc-clr-primary);
  /* Pagination */
  --vc-pgn-width: 10px;
  --vc-pgn-height: 5px;
  --vc-pgn-margin: 5px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}
.carousel__prev,
.carousel__next {
  background-color: var(--vc-nav-background-color);
  border-radius: var(--vc-nav-width);
  width: var(--vc-nav-width);
  height: var(--vc-nav-width);
  text-align: center;
  font-size: calc(var(--vc-nav-width) * 2 / 4);
  padding: 0;
  color: var(--vc-nav-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
}
</style>

