import axios from 'axios';
import { authHeader } from './auth-header';

import { URL_API } from './Commons';

class LinkService {

    async getAll() {
        return await axios.get(URL_API + 'links', { headers: authHeader() });
    }

    async getOne(id) {
        return axios.get(URL_API + 'link/' + id, { headers: authHeader() });
    }
}

export default new LinkService();