import axios from 'axios';
import {authHeader} from './auth-header';

import { URL_API } from './Commons';

class ApoioService {

    async getAll() {
        return await axios.get(URL_API + 'apoios', { headers: authHeader() });
    }

    async getOne(id) {
        return axios.get(URL_API + 'apoio/' + id, { headers: authHeader() });
    }


}

export default new ApoioService();