<template>
  <div class="container mt-4 mb-4">
    <div class="row bg-light mt-3 rounded">
      <nav class="nav">
        <div class="mx-auto mt-3 mb-3">
          <span v-for="link in linkList" :key="link.id">
            <a class="link-secondary ms-2 me-2" :href="link.url" target="_blank">{{
              link.titulo
            }}</a>
            <i class="bi bi-grip-vertical text-muted"></i>
          </span>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { URL_BASE } from "../services/Commons";
import LinkService from "../services/link.service";
export default {
  name: "LinkComponent",
  props: [],
  data: () => ({
    linkList: [],
    urlBase: "",
  }),
  mounted() {
    this.urlBase = URL_BASE;

    LinkService.getAll().then((result) => {    
      if (result.status < 400) {
        this.linkList = result.data;
      }
    });
  },
};
</script>